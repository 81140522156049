.App {
  text-align: center;
  font-family: fs-tahoma-8px;
  overflow: clip;
}

body {
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: fs-tahoma-8px;
  src: url(../public/fonts/fs-tahoma-8px.ttf);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.jrny-title {
  font-size: 15vh;
}

.HomeBckg {
  background-image: url('./static/imgs/app/windowsog1.webp');
  background-size: cover;
  /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.ShopifyPage {
  background-color: white;
  display: flex;
  position: relative;

}

.productList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product {
  border: solid;
  display: flex;
}


.productImageContainer {
  height: 100%;

  /* padding: 1svw; */
  /* border: solid; */
  /* height: 100%; */
  /* width: 10svh; */
}

.productImage {
  /* border: solid; */
  /* object-fit: cover; */
  margin: 1svw;
  box-shadow: rgb(226, 226, 226) 0px 0px 0px 3px;

  height: 100%;
  width: 100svw;
}

@media screen and (max-width: 800px) {
  .product {
    width: 100%;
  }

  .productImage {
    width: 30svw;
    height: 40svw;

  }
}

@media screen and (min-width: 1000px) {
  .productImage {
    height: 150%;
    width: 30svh;
  }
}

.productDescription {
  width: 30vw;
  flex-grow: 1;
  display: flex;
  background-color: white;
  flex-direction: column;

}

.CenterElement {
  margin-left: auto;
  margin-right: auto;
}

.productDescription1 {
  color: blue;
  font-size: 18px;
  flex-grow: 1;
  padding-top: 2.5%;
}

.marginTopS {
  padding-top: 3%;
}

.greenArrow {
  height: 50px;
  width: 50px;
}

.spinningEarth {
  height: 50px;
  width: 50px;
}

.flyingPlane {
  height: 80px;
  width: 80px;
  align-content: flex-end;
}

.addToCartBtn {
  height: 7vh;
  width: 6vw;

}

.addToCartBtn:hover {
  cursor: pointer;
  filter: brightness(85%);
}

.addToCartBtn:active {
  cursor: pointer;
  filter: brightness(75%);
}

.light-yellow-bckg {
  background-color: yellow;
}

.product-options-selector {
  margin-left: 0.5vw;
  color: #000000;
}

.product-options-selector-container {
  background-color: yellow;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 1vh;
}

.cartImg {
  height: 7vh;
  width: 7vw;
  align-content: center;
}

@media screen and (max-width: 800px) {
  .cartImg {
    height: 5vh;
    width: 10vw;
    align-content: center;
  }

}

.cartInfo {
  padding-top: 8%;
  display: inline-block;

}

.blackLine {
  width: 20px;
  height: 3px;
  background-color: black;
  margin: 6px 0;

}

.checkoutContainer {
  margin-top: 10%;
  width: 200px;
  margin: 0 auto;
}

.MediaContainer {
  border: solid;
  height: 100%;
  padding: 10%;
}

.rightSide {
  display: inline-block;
  border-color: black;
  border: solid;
  border-width: 5px;
  border-style: dotted;
  width: 100%;
}

.margin-bottom-L {
  margin-bottom: 100px
}

.margin-top-L {
  margin-top: 100px
}

.side-item-container {
  height: 400px;
}

.leftSide {
  width: 20vw;
  /* height: 10vh; */
  transition: left 0.35s ease-in-out;
  /* display: inline-block; */
}

@media screen and (max-width: 1000px) {
  .leftSide {
    width: 50vw;
    height: 65vh;
    z-index: 100;
    transition: left 0.35s ease-in-out;
    position: absolute;
    overflow: scroll;
    /* display: inline-block; */
  }
}

@media screen and (max-width: 700px) {
  .leftSide {
    z-index: 1000;
  }

}

.text-black {
  color: black;
}

.row1,
.row2,
.row3,
.row4 {
  display: flex;
  height: 250px;
  margin: 20px;
}


.column {
  height: 100%;
  width: 20%;
  border: solid;
  background-color: white;
  margin: 10px;
}

.VideosPageContainer {
  /* Replace this with the path to your image file */
  background-image: url('./static/gifs/galaxy1.gif');
}

.navbar {
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.navbar-items {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 18px;

}

.navbar-item:hover {
  color: #828385;
}

.navbar-item-selected {
  background-color: dimgray;
}

.music-page-container {
  /* background-color: #E7D3B3; */
}

.windows {
  border: 1px solid;
  border-left-color: #ffffff;
  border-top-color: #ffffff;
  background-color: #c3c7cb;
  border-right-color: #868a8e;
  position: sticky;

}

.windows-box {
  border: 1px solid;
  border-top-color: #c3c7cb;
  border-left-color: #c3c7cb;
  background-color: #ebe8db;
  border-bottom-color: #444;
  border-right-color: #000000;
}

.windows-container {
  background-color: #ebe8db;
}

.windows-header {
  margin: 1px 1px 1px 1px;
  padding: 1px 1px 1px 1px;
  padding-bottom: 10px;

  gap: 1em;

  background-image: -webkit-gradient(linear, left top, right top, from(#0000aa), to(#0082aa));

  background-image: -o-linear-gradient(left, #0000aa, #0082aa);

  background-image: linear-gradient(to bottom, #2A62D8, #2D66E4);
  ;
  height: 40px;
}

.windows-title {

  color: white;
  /* position: relative; */
  font-size: xx-large;


}

@media screen and (max-width: 1000px) {
  .windows-title {
    font-size: large;
  }

}

.windows-button {
  border: 2px solid;
  border-bottom-color: #444;
  border-right-color: #444;
  border-left-color: #a6a6a6;
  border-top-color: #a6a6a6;
  background-color: rgb(192, 192, 192);
}

button:active {
  background-color: rgb(192, 192, 192);
  border: 0.9px solid;
  border-bottom-color: rgb(0, 0, 0);
  border-right-color: rgb(0, 0, 0);
  border-left-color: #000000;
  border-top-color: #000000;
}

.cd-player {
  background-image: url('./static/imgs/music/cd-player.png');
  background-repeat: no-repeat;
  /* padding-bottom: 2px */
}

.windows-toolbar {
  list-style: none;
  background-color: #c0c0c0;
  /* display: inline; */
  /* margin-left: 0.075svw;
  margin-right: 0.075svw; */
  border: 0.9px solid;
  border-bottom-color: rgb(0, 0, 0);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  text-align: left;
  width: 100%;
}

.toolbar-item {
  margin-left: 15px;
  display: inline-block;
  list-style: none;
  padding: 1px;
  text-decoration: none;
}

.toolbar-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.toolbar-item:first-letter {
  text-decoration: underline;
  cursor: pointer;
}

.windows-link {
  color: inherit;
}

.clock-display {
  margin-top: 5px;
  border: 1px solid;
  border-bottom-color: #444;
  outline: 1px white;
  border-right-color: #444;
  border-left-color: #a6a6a6;
  border-top-color: #a6a6a6;
  background-color: black;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  display: flex;
  width: 100%;
  min-width: 65svw;


}

.text-clock-display {
  text-align: center;
  font-size: 16px;
  /* Font size based on viewport width */
  color: #aaaa55;
}

@media screen and (max-width: 800px) {
  .text-clock-display {
    font-size: 40px;
  }

  .custom-player-timer {
    font-size: 5vw;
  }
}

.player-dispay-info-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  color: #aaaa55;
  font-size: 16px;
  margin-right: 20vw;
}

.player-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 45px;
  padding-top: 5px;
}

.custom-player-timer {
  padding: 2vw;
}

.play-button {
  border: 1px solid;
  border-top-color: #ffffff;
  border-left-color: #dbdada;
  background-color: #c0c0c0;
  border-bottom-color: #000000;
  border-right-color: #000000;
  width: 15%;
  height: 17%;
  font-family: "ms95";
}

.player-button {
  padding-left: 3vw;
  padding-top: 3vw;
  padding-right: 10vw;
  margin: 1px;
  margin-bottom: 5px;
  border: 1px solid;
  border-top-color: #ffffff;
  border-left-color: #dbdada;
  background-color: #c0c0c0;
  border-bottom-color: #000000;
  border-right-color: #000000;
  /* width: 3vw; */
  /* height: 4vw; */
  font-family: "ms95";
  padding: 5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.player-button-icon {
  padding-left: 5vw;
  padding-right: 5vw;

}

.player-input {
  padding-left: 5px;
  width: 100%;
  height: 7%;
  font-family: "ms95";
  font-size: 13px;
  border: 1px solid;
  border-top-color: #000000;
  border-left-color: #000000;
  ;
  background-color: #ffffff;
  border-bottom-color: #c3c7cb;
  border-right-color: #c3c7cb;
}

.player-input-of {
  padding-left: 5px;
  width: 100%;
  height: 7%;
  font-family: "ms95";
  font-size: 13px;
  border: 1px solid;
  border-top-color: #000000;
  border-left-color: #000000;
  background-color: #c3c7cb;
  border-bottom-color: #c3c7cb;
  border-right-color: #c3c7cb;
}


.player-input-status {
  padding-left: 5px;
  width: 50%;
  height: 7%;
  font-family: "ms95";
  font-size: 13px;
  border: 1px solid;
  border-top-color: #000000;
  border-left-color: #000000;
  background-color: #c3c7cb;
  border-bottom-color: #c3c7cb;
  border-right-color: #c3c7cb;
}



.player-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  /* Ensure items do not wrap to the next line */
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  /* Adjust spacing between items */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  /* Align items vertically if needed */
  margin: 0;
  /* Remove or reduce margin */
  padding: 0;
  /* Remove or reduce padding */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* Include padding and border in the element's total size */
  width: 100%;
  /* Ensure it fits within the parent container */
  height: auto;
  /*
      /* Ensure items do not wrap to the next line */
  align-items: center;
}

.player-container>div {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;

  margin: 10px;

}

.player-container-column {
  margin: 10px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.col-s6 {}


.overlay-win {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.player-display-thumbnail {
  height: 50px;
  width: 50px;
  -o-object-fit: fill;
  object-fit: fill;
}

.media-list {
  flex-grow: 1;
  /* height: 20vw; */
  overflow: scroll;
  background-color: white;
  border: 1px solid #fff;
  padding: 10px;
}

.disc-list {
  flex-grow: 1;
  /* height: 20vw; */
  overflow: scroll;
  background-color: #BFBFBF;
  border: 1px solid #fff;
  padding: 10px;
  position: absolute;
  top: 100;
  left: 100;
  z-index: 2;
}

.media-list table {
  width: 100%;
  border-collapse: collapse;
}

.media-list table th,
.media-list table td {
  border: 1px solid #fff;
  padding: 5px;
  text-align: left;
}

/* 
.media-list table th {
  background-color: #eef;
} */

.borderSpinning {
  margin: 0 auto;
  width: max-content;
  background: linear-gradient(90deg, green 50%, transparent 50%), linear-gradient(90deg, green 50%, transparent 50%), linear-gradient(0deg, green 50%, transparent 50%), linear-gradient(0deg, green 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
  padding: 10px;
  animation: border-dance 4s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }

  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}


.border-solid {
  border: solid;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.sidebar {
  background-color: #fff;
  border-right: 1px solid #ddd;
  padding: 0 1vw 0 1vw;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* margin-top: 15%; */
}

.sidebar h2 {
  font-size: 5vw;

  margin-bottom: 20px;
  color: blue;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

@media screen and (min-width: 1200px) {
  .sidebar h2 {
    font-size: 26px;
  }
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-list>li {
  margin-bottom: 10px;
}

.sidebar-list button {
  background: none;
  border: none;
  color: blue;
  font-size: 34px;
  text-align: left;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
}

.sidebar-list button:hover {
  text-decoration: underline;
}

.sub-list {
  list-style-type: none;
  padding-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sub-list li {
  font-size: 15px;
  color: blue;
  padding: 5px 0;
}

.product-img {
  height: 100%;
}

.fiftypercent {
  margin: 0 auto;
}

.footer {
  height: 2vw;
  display: flex;
  flex-direction: row;
  position: sticky;
  bottom: 0;
  background: linear-gradient(to bottom,
      #4282d6 0%,
      #3b85e0 3%,
      #418ae3 5%,
      #418ae3 17%,
      #3c87e2 21%,
      #3786e4 26%,
      #3482e3 29%,
      #2e7ee1 39%,
      #2374df 49%,
      #2072db 57%,
      #196edb 62%,
      #176bd8 72%,
      #1468d5 75%,
      #1165d2 83%,
      #0f61cb 88%);
}

@media screen and (max-width: 1200px) {
  .footer {
    height: 5vw;
  }
}

.footer__start {

  height: 100%;
  margin-right: 0px;
  position: relative;
}

.footer__start:hover {
  filter: brightness(105%);
}

.footer__start:active {
  pointer-events: none;
  filter: brightness(85%);
}

.footer__window {
  padding: 2px;
  flex: 1;
  max-width: 150px;
  color: #fff;
  border-radius: 2px;
  margin-top: 2px;
  /* padding: 0 8px;
  height: 22px; */
  font-size: 11px;
  background-color: #3c81f3;
  box-shadow: inset -1px 0px rgba(0, 0, 0, 0.3),
    inset 1px 1px 1px rgba(255, 255, 255, 0.2);
  position: relative;
  display: flex;
  align-items: center;
}

.footer__window:hover {
  cursor: pointer;
  background-color: #53a3ff;
  box-shadow: inset -1px 0px rgba(0, 0, 0, 0.3),
    inset 1px 1px 1px rgba(255, 255, 255, 0.2);
}

.footer__icon {
  height: 75%;

}

.footer__text {
  position: absolute;
  left: 27px;
  right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer__window.cover:hover {
  background-color: #53a3ff;
  box-shadow: inset -1px 0px rgba(0, 0, 0, 0.3),
    inset 1px 1px 1px rgba(255, 255, 255, 0.2);
}

.footer__window.cover:before {
  display: block;
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  width: 10px;
  height: 1px;
  border-bottom-right-radius: 50%;
  box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
}

.footer__window.cover:hover:active {
  background-color: #1e52b7;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3),
    inset 1px 0 1px rgba(0, 0, 0, 0.7);
}

.footer__window.focus:hover {
  background-color: #3576f3;
}

.footer__window.focus:hover:active {
  background-color: #1e52b7;
}

.footer__window.focus {
  background-color: #1e52b7;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.2),
    inset 1px 0 1px rgba(0, 0, 0, 0.7);
}

.searchBar {
  width: 100%;
}

.tumblrPlayer {
  width: 70%;
}

.shopifyTitle {
  color: blue;
  font-size: 36px;
}

.text-underlined {
  text-decoration: underline;
  text-decoration-color: blue;
}

.jrny-title {
  position: relative;
  color: black;
  font-weight: bold !important;
  letter-spacing: 4px !important;
  overflow: hidden;
  white-space: nowrap;
  animation: typewriter 2s steps(44) 1s 1 normal both;
}

.media-list-item {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  gap: 1vw;
}

.music-player-menu-item {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 1vw;
}

.music-player-menu-item:hover {
  filter: brightness(75%);
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .music-player-menu-item {
    font-size: 3vw;
  }

}

.media-list-item:hover {
  filter: brightness(75%);
  cursor: pointer;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 710px;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: black;
  }

  to {
    border-right-color: transparent;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.flash-color-text {
  animation: blinker 0.8s linear infinite;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 100vh;
  /* Set to full viewport height */
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}


.desktopIconsContainer {
  display: flex;
  gap: 7.5vw;
  position: fixed;
  top: 1vw;
  left: 1vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.desktopIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  padding: 1vw;
  flex-direction: column;
  font-size: larger;
  color: white;
  padding-left: 2vw;

}

.desktopIcon {
  width: 10vw;
  height: 5vh;
}

.desktopIcon:hover {
  filter: brightness(75%);
  cursor: pointer;
}

@media screen and (min-width: 800px) {
  .desktopIcon {
    width: 3vw;
    height: 2.75vw;
  }

  .desktopIconsContainer {
    flex-direction: column;
    gap: 1vw;
  }
}

.youtube-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;

}

.cartCount {
  padding-right: 10vw;
  border-radius: 100px;
  background-color: green;
  color: white;
  font-size: 2vw;
  padding: 0.5vw;
}

@media screen and (max-width: 1000px) {

  .cartCount {
    font-size: 5vw;
  }


}

.Shopify-Title {
  font-size: 36px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #C0C0C0;
  padding: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  /* The height of the footer matches the icon height */
  box-sizing: border-box;
  font-size: 18px;
  /* Larger font size for desktop readability */
}

.footer__start {
  height: 49px;
  /* Ensure the Start button takes the full height of the footer */
  max-width: 180px;
  /* Larger width for desktop */
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: -12px;
  /* Ensure content inside the Start button is vertically centered */
}

.footer__window {
  display: flex;
  align-items: center;
  height: 100%;
  /* Ensure the icons take the full height of the footer */
  /* Adjust icon spacing */
}

.footer__icon {
  height: 90%;
  /* Ensure the icons take the full height of the footer */
  margin-right: 10px;
  /* Space between icons */
}

.footer__start img {
  height: 100%;
  /* Ensure the image inside the Start button is the full height of the button */
}

.footer__time {
  justify-self: flex-end;
  padding: 10px;
  height: 45px;
  border-radius: 5px;
  background-color: #3c81f3;
  font-size: larger;
  margin-left: auto;
  /* Reduced padding to align the time widget closer to the right */
  color: white;
  /* Ensure the time widget fills the height of the footer */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* Ensures time is aligned right */
  cursor: pointer;
  margin-right: -10px;
}

/* Media query for smaller screens (mobile) */
@media (max-width: 600px) {
  .footer {
    height: 40px;
    /* Slightly smaller footer height for mobile */
    font-size: 14px;
    /* Smaller font size for mobile */
  }

  .footer__time {
    height: 40px;
  }

  .footer__start {
    margin-left: -12px;
    width: 120px;
    /* Smaller Start button width for mobile */
    height: 44px;
    /* Ensure the Start button takes the full height of the footer */
  }

  .footer__icon {
    margin-right: 5px;
    /* Less space between icons on mobile */
  }

  .footer__time {
    font-size: 14px;
    /* Smaller time font on mobile */
    padding-right: 5px;
    /* Align the time widget closer to the right on mobile */
  }

  /* Hide text from the icons in mobile */
  .footer__text {
    display: none;
    /* Hide the text in mobile view */
  }
}



.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.modal__content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 250px;
  /* Default for smaller screens */
  font-size: 16px;
  /* Default font size */
}

.modal__close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* Media query for desktop to make the modal 3 times larger */
@media (min-width: 1024px) {
  .modal__content {
    max-width: 750px;
    /* 3x the original width */
    padding: 40px;
    /* Increase padding for readability */
    font-size: 24px;
    /* Increase font size for desktop */
  }
}