/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.player {

    /* Made with love by Chyper*/
    /* @font-face {
        font-family: 'ms95';
        src: url("./ms-sans-serif.ttf");
    }


    * {
        font-family: "ms95"
    } */



    body {
        font-family: "ms95";
        font-size: 13px;
        background-color: #008080;
        text-decoration: none;
        -webkit-transform: scale(1.9);
        -ms-transform: scale(1.9);
        transform: scale(1.9);
        -webkit-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
        overflow: hidden;

    }

    .windows {
        border: 1px solid;
        border-left-color: #ffffff;
        border-top-color: #ffffff;
        background-color: #c3c7cb;
        border-right-color: #868a8e;

    }

    .windows-box {
        border: 1px solid;
        border-top-color: #c3c7cb;
        border-left-color: #c3c7cb;
        background-color: #c0c0c0;
        border-bottom-color: #444;
        border-right-color: #000000;
    }

    .windows-header {
        margin: 1px 1px 1px 1px;
        padding: 1px 1px 1px 1px;
        padding-bottom: 10px;

        gap: 1em;

        background-image: -webkit-gradient(linear, left top, right top, from(#0000aa), to(#0082aa));

        background-image: -o-linear-gradient(left, #0000aa, #0082aa);

        background-image: linear-gradient(to right, #0000aa, #0082aa);
        ;
        height: 40px;
    }

    .windows-title {
        padding-top: 1px;
        padding-bottom: 10px;
        color: white;
        /* position: relative; */

        -webkit-box-orient: vertical;

        -webkit-box-direction: normal;

        -ms-flex-direction: column;

        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 4em;

        -webkit-box-align: left;

        -ms-flex-align: left;

        align-items: left;
        text-align: left;
    }

    .windows-button {
        border: 2px solid;
        border-bottom-color: #444;
        border-right-color: #444;
        border-left-color: #a6a6a6;
        border-top-color: #a6a6a6;
        background-color: rgb(192, 192, 192);
    }

    button:active {
        background-color: rgb(192, 192, 192);
        border: 0.9px solid;
        border-bottom-color: rgb(0, 0, 0);
        border-right-color: rgb(0, 0, 0);
        border-left-color: #000000;
        border-top-color: #000000;
    }

    .cd-player {
        background-image: url('../../static/imgs/music/cd-player.png');
        background-repeat: no-repeat;
        padding-left: 20px;
        padding-bottom: 2px
    }

    .windows-toolbar {
        list-style: none;
        /* display: inline; */
        padding: 2px;
        margin-left: -10px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: left;
        -ms-flex-align: left;
        align-items: left;
        text-align: left;
    }

    .toolbar-item {
        margin-left: 15px;
        display: inline-block;
        list-style: none;
        padding: 1px;
        text-decoration: none;
    }

    .toolbar-item:first-letter {
        text-decoration: underline;
        cursor: pointer;
    }

    .windows-link {
        color: inherit;
    }

    .clock-display {
        padding-left: 10px;
        margin-top: 5px;
        border: 1px solid;
        border-bottom-color: #444;
        outline: 1px white;
        border-right-color: #444;
        border-left-color: #a6a6a6;
        border-top-color: #a6a6a6;
        background-color: black;
        width: 90%;
        max-width: 90vw;
        height: 25%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .text-clock-display {
        text-align: center;
        font-size: 1vw;
        /* Font size based on viewport width */
        color: #aaaa55;
    }

    .player-control {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 45px;
        padding-top: 5px;
    }

    .custom-player-timer {
        font-size: 1vw;
        padding: 2vw;
    }

    .play-button {
        border: 1px solid;
        border-top-color: #ffffff;
        border-left-color: #dbdada;
        background-color: #c0c0c0;
        border-bottom-color: #000000;
        border-right-color: #000000;
        width: 15%;
        height: 17%;
        font-family: "ms95";
    }

    .player-button {
        margin: 1px;
        margin-bottom: 5px;
        border: 1px solid;
        border-top-color: #ffffff;
        border-left-color: #dbdada;
        background-color: #c0c0c0;
        border-bottom-color: #000000;
        border-right-color: #000000;
        width: 40%;
        height: 40%;
        font-family: "ms95";
        padding: 5px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
    }

    .player-button-icon {
        height: 75%;
        width: 75%;
    }

    .player-input {
        padding-left: 5px;
        width: 100%;
        height: 7%;
        font-family: "ms95";
        font-size: 13px;
        border: 1px solid;
        border-top-color: #000000;
        border-left-color: #000000;
        ;
        background-color: #ffffff;
        border-bottom-color: #c3c7cb;
        border-right-color: #c3c7cb;
    }

    .player-input-of {
        padding-left: 5px;
        width: 100%;
        height: 7%;
        font-family: "ms95";
        font-size: 13px;
        border: 1px solid;
        border-top-color: #000000;
        border-left-color: #000000;
        background-color: #c3c7cb;
        border-bottom-color: #c3c7cb;
        border-right-color: #c3c7cb;
    }


    .player-input-status {
        padding-left: 5px;
        width: 50%;
        height: 7%;
        font-family: "ms95";
        font-size: 13px;
        border: 1px solid;
        border-top-color: #000000;
        border-left-color: #000000;
        background-color: #c3c7cb;
        border-bottom-color: #c3c7cb;
        border-right-color: #c3c7cb;
    }



    .player-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        /* Ensure items do not wrap to the next line */
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        /* Adjust spacing between items */
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        /* Align items vertically if needed */
        margin: 0;
        /* Remove or reduce margin */
        padding: 0;
        /* Remove or reduce padding */
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        /* Include padding and border in the element's total size */
        width: 100%;
        /* Ensure it fits within the parent container */
        height: auto;
        /*
        /* Ensure items do not wrap to the next line */

    }

    .player-container>div {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        margin: 10px;

    }

    .player-container-column {
        margin: 10px;
    }

    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .col-s6 {}


    .overlay-win {
        position: fixed;
        /* Sit on top of the page content */
        display: none;
        /* Hidden by default */
        width: 100%;
        /* Full width (cover the whole page) */
        height: 100%;
        /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        /* Black background with opacity */
        z-index: 2;
        /* Specify a stack order in case you're using a different order for other elements */
        cursor: pointer;
        /* Add a pointer on hover */
    }

    .player-display-thumbnail {
        height: 50px;
        width: 50px;
        -o-object-fit: fill;
        object-fit: fill;
    }
}